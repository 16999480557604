.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the header area */
}

html,
body {
  scroll-behavior: smooth;
}

.header {
  position: fixed;
  width: 100%;
  height: 100px; /* Adjust height as needed */
  overflow: hidden;
  z-index: 1000;
}

.header-content {
  position: absolute;
  top: 50%;
  left: 22%;
  transform: translate(-50%, -50%);
  text-align: left;
  color: white;
  padding: 20px;
}

.header h1 {
  font-size: 3vw;
}

.header-content-phone {
  display: none;
}

.header nav ul li:hover {
  border-bottom: 2px solid white;
}

@media (max-width: 480px) {
  .header-content {
    align-self: center;
    align-items: center;
    left: 50%;
    font-size: small;
  }
  .header h1 {
    display: none;
  }
}
