.headshot {
  max-width: 50%;
  align-items: center;
}

.page-image {
  min-height: 100vh;
  background: url("../images/background.JPG");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rounded-img {
  width: 300px; /* Adjust size as needed */
  height: 300px; /* Adjust size as needed */
  border-radius: 50%; /* This makes the image circular */
  border: 7px solid #321124; /* Optional: adds a border around the image */
}

h1 {
  color: white;
  font-size: 70px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin: 0;
}

h2 {
  color: white;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

@media (max-width: 480px) {
  h1 {
    font-size: 12vw;
  }
}
