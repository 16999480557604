.resume {
  padding-left: 5%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.screen {
  display: flex;
  height: 120vh;
  flex-direction: column;
  background-repeat: no-repeat;
  padding: 5%;
}

.header-image {
  background: url("../images/header.jpeg") repeat-x;
  background-size: 300px;
  width: 100%;
}

.move-left h1 {
  margin-top: 5%;
  color: black;
}

.screen h2,
.screen p {
  color: black;
  align-self: center;
}

.screen button {
  width: 25%;
  align-self: center;
  margin: 2%;
}

@media (max-width: 480px) {
  .move-left h1 {
    margin-top: 18%;
  }

  .screen button {
    width: 30%;
  }
}
