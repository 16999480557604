body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header {
  background-color: purple;
  color: #fff;
  text-align: left;
}

header h1 {
  margin: 0;
  text-align: left;
  display: inline;
}

section > *:first-child {
  margin-top: 0;
}

section > *:last-child {
  margin-bottom: 0;
}

section {
  scroll-margin-top: 75px;
}

nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin: 0 1rem;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
}

footer {
  background-color: purple;
  color: #fff;
  text-align: center;
  padding: 1rem;
  position: fixed;
  width: 100%;
  bottom: 0;
}
